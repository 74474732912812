




















































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { getComponent, jsonParse } from "@/utils/helpers";
import accountModule from "@/store/modules/accountModule";
import assetsModule from "@/store/modules/assetsModule";
import scheduleModule from "@/store/modules/scheduleModule";
import { mixins } from "vue-class-component";
import Multiselect from "vue-multiselect";
import workspaceModule from "@/store/modules/workspaceModule";
import { getNameByEmail } from '@/utils/users';

@Component({
  components: {
    Multiselect,
  },
})
export default class ScheduleV2 extends mixins() {
  selectedColumnType = "DETAILS";
  userSelectionVal = "";
  yourValue = "";

  // NEEDED
  refreshResponseDataKey = 0;
  editShiftChecklistPopup = false;
  editShiftIsLoading = false;

  // NEEDED
  editShiftGroupSelected = "";

  // Loading views
  dataLoading = false;
  userOptions: any[] = [];
  assetOptions: any[] = [];
  assetType = "";
  errorMessages = { assetType: "", assetName: "", username: "" }
  dataErrorMessage = "";
  userName = "";
  assetName = "";
  allAssetTypes: any;
  shiftStartTime='';
  shiftEndTime='';
  shiftColor = '';
  disableShiftStartTime = false;
  disableShiftEndTime = false;

  get activeComponent() {
    return scheduleModule.activeComponent;
  }

  get activeColumns() {
    return scheduleModule.activeComponentResponseColumns;
  }

  get scheduledRowComponents(): any[] {
      return scheduleModule.scheduledRowComponents
  }

  // NEEDED
  closeEditShiftPopup(component_id) {
    this.$emit("close-edit-shift-popup", component_id);
  }

  async editShiftSave() {
    // @ts-ignore

    var errorFlag = false;
    for (var k in this.errorMessages) {
      this.errorMessages[k] = "";
    }

    if (this.assetType == '') {
      errorFlag = true;
      this.errorMessages.assetType = "Please select asset type"
    }

    if (this.assetName == '') {
      errorFlag = true;
      this.errorMessages.assetName = "Please select asset name"
    }

    if (this.userName == '') {
      errorFlag = true;
      this.errorMessages.username = "Please select a user"
    }

    if (errorFlag)
      return;

    scheduleModule.addPersonnelScheduleShifts({
      type: 'edit',
      username: this.userName,
      startTime: this.shiftStartTime,
      endTime: this.shiftEndTime,
  		assetType: this.assetType,
	  	asset: this.assetName,
      // color: this.shiftColor,
      RowID: this.activeComponent.RowID,
      ShiftID: this.activeComponent.ID
    })
    // @ts-ignore
    this.closeEditShiftPopup(this.activeComponent.ID);
  }

  editShiftResponseData(editShiftResponseData: any) {
    throw new Error("Method not implemented.");
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  async setUserOptions(){
    this.userOptions.push(scheduleModule.accountDetails.map(item=>{
      return { value: item.email, text: item.name }
    })) 
  }
  async getAllAssetOptions(){
    var detailedComponent, currentComponent;
    this.allAssetTypes = {
      "Area": [],
      "Pad": [],
      "Well": [],
      "Route": []
    }
    for (var x = 0; x < this.scheduledRowComponents.length; x++) {
      detailedComponent = this.getFullComponentDetailsByID(this.scheduledRowComponents[x].title_row.selection_id)
      if (detailedComponent != null) {
        this.allAssetTypes[detailedComponent.AssetType].push(detailedComponent.Asset)
        if (this.activeComponent.RowID == detailedComponent.ID)
          currentComponent = detailedComponent;
      }
		}
    // this.allAssetTypes = await scheduleModule.getOperatorAssets();
    this.setItemDetails();
    if (currentComponent)
      this.assetName = currentComponent.Asset
  }
  getFullComponentDetailsByID(id) {
		if (scheduleModule.activePersonnelSchedule != null) {
			// @ts-ignore
			for (var x = 0; x < scheduleModule.activePersonnelSchedule.Rows.length; x++) {
					// @ts-ignore
        if (id == scheduleModule.activePersonnelSchedule.Rows[x].ID) {
          // @ts-ignore
          return scheduleModule.activePersonnelSchedule.Rows[x]
        }
      }
		}
	}

  async setAssetOptions(){
    if (this.allAssetTypes) {
      // this.assetOptions = JSON.parse(this.allAssetTypes[`${this.assetType}s`]) 
      this.assetName = ''
      this.assetOptions = this.allAssetTypes[`${this.assetType}`]
    }
  }

  setItemDetails() {
    if (this.activeComponent) {
      this.shiftStartTime = this.activeComponent.StartTime
      this.shiftEndTime = this.activeComponent.EndTime
      this.assetType = this.activeComponent.AssetType
      this.setAssetOptions();
      this.userName = this.activeComponent.Username
    }
  }

  created() {
    this.setUserOptions(),
    this.getAllAssetOptions();
  }
}
